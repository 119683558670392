import React from 'react'

import useSiteMetadata from './SiteMetadata'

import spotify from '../img/streaming/logo-spotify.svg'
import apple from '../img/streaming/logo-apple.svg'
import amazon from '../img/streaming/logo-amazonmusic.svg'
import youtube from '../img/streaming/logo-youtube.svg'
import deezer from '../img/streaming/logo-deezer.svg'
import tidal from '../img/streaming/logo-tidal.svg'

const StreamingList = ({ limit }) => {

    const { streaming } = useSiteMetadata();
    const logos = {
        "spotify": spotify,
        "apple": apple,
        "amazon": amazon,
        "youtube": youtube,
        "tidal": tidal,
        "deezer": deezer,
    };

    return (
        <div className="grid grid-cols-6 md:grid-cols-12 items-center gap-24 lg:gap-56 2xl:gap-x-88">
            {Object.keys(streaming).map((item, index) => {
                if (index >= limit) {
                    return null;
                }
                                
                return (
                    <div className="col-span-3 md:col-span-3">
                        <a href={ streaming[item] } target="_blank" rel="noreferrer">
                            <img src={ logos[item] } alt={ item } />
                        </a>
                    </div>
                )
            })}
        </div>
    )
}

export default StreamingList
